<template>
  <el-container>
    <!--    导航条-->
    <el-header>
      <Header></Header>
    </el-header>
    <!--    课程内容-->
    <el-main class="container">
      <el-row :gutter="20">
        <el-col :md="18" :xs="24">
          <el-row>
            <div class="courseName">{{ name }}</div>
            <el-button type="primary" style="width:200px;float:right" @click="dialogTableVisible = true">加入课程
            </el-button>
          </el-row>
          <el-divider></el-divider>
          <el-row :gutter="20">
            <el-col :md="8" :xs="24">
              <el-image :src="imageUrl" style="margin-top: 1.1rem"></el-image>
            </el-col>
            <el-col :md="16" :xs="24">
              <p style="text-align: justify;text-justify: inter-ideograph;">
                {{ sketch }}
              </p>
            </el-col>

          </el-row>
          <el-row class="course_top" justify="center" :gutter="5">
            <el-row class="course_description">
              <el-col>

              </el-col>
              <el-col style="margin-top:10px;text-align: center">

              </el-col>
            </el-row>
            <!--        右侧课程名-->
            <el-col :span="12" :xs="24" :sm="6" :md="12" :lg="12">

              <!--          描述-->

              <!--        按钮-->
            </el-col>
          </el-row>
          <!--      课程详情,课程评价-->
          <el-row class="courseInfo">
            <el-tabs stretch active-name="first">
              <el-tab-pane label="课程详情" name="first">
                <el-row>
                  <iframe :src="htmlUrl" frameborder="0" width="100%"></iframe>
                </el-row>
                <!--            提示性信息-->
                <el-alert
                  type="info"
                  description="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab amet animi atque cupiditate dolores ea eius eos eum ex explicabo, iusto, nesciunt pariatur provident repellendus sapiente soluta veritatis voluptate voluptatibus!"
                  closable="false">
                </el-alert>
                <!--            课程概述,授课目标-->
                <el-row>
                  <el-col class="courseConclusion"><i class="el-icon-s-operation"></i>&nbsp;课堂概述</el-col>
                  <el-col>
                <span>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. A ab, architecto consectetur cumque doloremque expedita explicabo facilis in labore magnam, magni modi molestias, nisi porro provident quis ut vitae voluptatem?
                </span>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col class="courseConclusion"><i class="el-icon-s-operation"></i>&nbsp;授课目标</el-col>
                  <el-col>
                <span>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. A ab, architecto consectetur cumque doloremque expedita explicabo facilis in labore magnam, magni modi molestias, nisi porro provident quis ut vitae voluptatem?
                </span>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col class="courseConclusion"><i class="el-icon-s-grid"></i>&nbsp;课程大纲</el-col>
                  <el-col>
                <span>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. A ab, architecto consectetur cumque doloremque expedita explicabo facilis in labore magnam, magni modi molestias, nisi porro provident quis ut vitae voluptatem?
                </span>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col class="courseConclusion"><i class="el-icon-document"></i>&nbsp;预备知识</el-col>
                  <el-col>
                <span>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. A ab, architecto consectetur cumque doloremque expedita explicabo facilis in labore magnam, magni modi molestias, nisi porro provident quis ut vitae voluptatem?
                </span>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col class="courseConclusion"><i class="el-icon-collection-tag"></i>&nbsp;合格要求</el-col>
                  <el-col>
                <span>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. A ab, architecto consectetur cumque doloremque expedita explicabo facilis in labore magnam, magni modi molestias, nisi porro provident quis ut vitae voluptatem?
                </span>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col class="courseConclusion"><i class="el-icon-attract"></i>&nbsp;参考资料</el-col>
                  <el-col>
                <span>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. A ab, architecto consectetur cumque doloremque expedita explicabo facilis in labore magnam, magni modi molestias, nisi porro provident quis ut vitae voluptatem?
                </span>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col class="courseConclusion"><i class="el-icon-s-operation"></i>&nbsp;讲课名师</el-col>
                  <el-col :span="6" :xs="24" :sm="9" :md="6" :lg="6">
                    <el-avatar :size="180" shape="circle" :src="src"></el-avatar>
                  </el-col>
                  <el-col :span="18" :xs="24" :sm="9" :md="18" :lg="18">
                    <el-card shadow="always" style="height:180px">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Explicabo ipsam iusto maiores officiis
                      praesentium quam, voluptas. Eaque, esse itaque necessitatibus nisi omnis quaerat sapiente sed sunt
                      velit veniam, voluptate voluptates.
                    </el-card>
                  </el-col>
                </el-row>
              </el-tab-pane>
              <el-tab-pane label="课程评价" name="second">
              </el-tab-pane>
            </el-tabs>
          </el-row>
        </el-col>
        <el-col :md="6" :xs="24">
          <div id="sideBox" class="sideBox">
            <transition name="el-fade-in-linear">
              <el-button type="primary" style="width:200px;float:right;margin-bottom: 10px"
                         @click="dialogTableVisible = true"
                         v-show="sideButton"
              >加入课程
              </el-button>
            </transition>
            <div class="teacher">
              <img
                src="https://cos.mooc.huangzhaokun.top/moocImg/W020160629352592446326.jpg"
                alt="">
              <span>聂笑一</span>
            </div>
            <div class="otherCourse">
              <p>此老师的其他课程:</p>
              <el-table
                :show-header="false"
                :data="otherCourses"
                style="width: 100%">
                <el-table-column
                  prop="courseName"
                  width="180">
                </el-table-column>
              </el-table>
            </div>
            <div class="school">
              <b-icon icon="building" font-scale="2"></b-icon>
              <span>湖南农业大学</span>
            </div>
            <div class="otherCourse">
              <p>此学校的其他课程:</p>
              <el-table
                :show-header="false"
                :data="otherCourses"
                style="width: 100%">
                <el-table-column
                  prop="courseName"
                  width="180">
                </el-table-column>
              </el-table>
            </div>
          </div>
        </el-col>
      </el-row>
    </el-main>
    <el-dialog title="课程学期" :visible.sync="dialogTableVisible">
      <el-table :data="termData" ref="termtable" v-if="termTableAlive">
        <el-table-column property="name" label="学期"></el-table-column>
        <el-table-column property="startTime" label="开始时间"></el-table-column>
        <el-table-column property="endTime" label="结束时间"></el-table-column>
        <el-table-column property="" label="操作">
          <template slot-scope="scope">
            <el-button v-if="scope.row.selected" type="success" plain @click="goCourse(scope.row.id)">进入课程</el-button>
            <el-button v-else :type="scope.row.status=== 'ongoing' ? 'success' : 'info'"
                       @click="addTerm(scope.row.id)">加入
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </el-container>
</template>

<script>
import axios from 'axios'
import Header from '@/components/Header'

export default {
  name: 'Home',
  components: {
    Header
  },
  data () {
    return {
      courseId: '',
      termTableAlive: true,
      sideButton: false,
      name: '',
      imageUrl: '',
      sketch: '',
      htmlUrl: '',
      termData: [],
      dialogTableVisible: false,
      studentId: '',
      flag: false,
      otherCourses: [{ courseName: '三天学会java' },
        { courseName: '三天学会vue' },
        { courseName: 'ps实战' }
      ]
    }
  },
  mounted () {
    window.addEventListener('scroll', this.scrollToTop)
  },
  created () {
    this.checkLogin()
    this.courseId = this.$route.params.courseId
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  },
  beforeRouteLeave (to, form, next) {
    window.removeEventListener('scroll', this.scrollToTop)
    next()
  },
  methods: {
    reload () {
      this.termTableAlive = false
      this.$nextTick(function () {
        this.termTableAlive = true
      })
    },
    scrollToTop () {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      const sideBox = document.getElementById('sideBox')
      if (scrollTop >= 250) {
        sideBox.classList.add('fixedBox')
        this.sideButton = true
      } else {
        sideBox.classList.remove('fixedBox')
        this.sideButton = false
      }
    },
    goCourse (id) {
      this.$router.push({
        name: 'CourseMain',
        params: { termId: id }
      })
    },
    checkLogin () {
      axios({
        methods: 'GET',
        url: 'https://mooc.kirinnorine.top/student/checkLogin'
      }).then(res => {
        if (!res.data.status) {
          this.$message.error('学生未登录,请登录后再操作!')
          this.$router.push({ path: '/login' })
        } else {
          this.studentId = res.data.data.id
          this.getMessage()
          this.getTerms()
        }
      })
    },
    addTerm (key) {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/selection/selectCourse',
        params: {
          termId: key
        }
      }).then(res => {
        if (res.status) {
          if (res.data.status) {
            this.$message.success(res.data.message)
            this.getTerms()
            this.reload()
          } else {
            this.$message.error(res.data.message)
          }
        }
      })
    },
    getTerms () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/term/showTermsByCourseId',
        params: {
          courseId: this.courseId,
          currentPage: 1,
          pageSize: 10,
          checkSelected: true
        }
      }).then(res => {
        if (res.status) {
          this.termData = res.data.data.content
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    getMessage () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/course/showCourseById',
        params: {
          id: this.courseId
        }
      }).then(response => {
        const datas = response.data.data
        if (response) {
          this.name = datas.name
          this.imageUrl = datas.courseInfo.imageUrl
          this.sketch = datas.courseInfo.sketch
          this.htmlUrl = datas.courseInfo.infoUrl
        } else {
          this.$message.error(response.data.message)
        }
      })
    }
  }
}
</script>

<style scoped>
.school {
  margin-top: 20px;
}

.school span {
  font-size: 20px;
  margin-left: 5px;
}

.course_top {
  margin-top: 60px
}

.course_description .courseInfo {
  margin-top: 20px
}

.courseName {
  margin: 50px 0 10px 0;
  font-size: 30px
}

.courseInfo {
  margin-top: 20px;
}

i {
  font-size: 40px;
  color: rgb(115, 159, 202);
}

.courseConclusion {
  font-size: 35px;
  font-weight: bold;
  margin-top: 40px;
  color: #666666

}

.sideBox {
  margin-top: 190px;
  margin-left: 20px;
  padding: 0 0 0 30px;
  border-left: 1px solid #dee2e6 !important;
}

.fixedBox {
  margin-top: 0 !important;
  position: fixed;
  z-index: 100;
}

.teacher {
  vertical-align: middle;
}

.teacher img {
  border-radius: 50%;
  height: 50px;
  width: 50px;
  object-fit: cover;
  vertical-align: middle;
}

.teacher span {
  font-size: 20px;
  margin-left: 5px;
}
</style>
